<template>
  <div id="app">
    <el-container direction="vertical">
      <el-main>
        <el-row type="flex" class="row-bg" justify="space-around" style="margin: 5vh 0px">
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
            <el-image src="charon.png"/>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-around">
          <el-col :xs="24" :sm="16" :md="12" :lg="10" :xl="8">
            <el-card shadow="always" id="inputContent">
              <el-input :placeholder="$t('login.accountHint')" v-model="userInfo.usrName">
                <template slot="prepend"><i class="el-icon-user"/></template>
              </el-input>
              <el-input :placeholder="$t('login.passwordHint')" v-model="userInfo.usrPwd" show-password>
                <template slot="prepend"><i class="el-icon-lock"/></template>
              </el-input>
              <el-row style="margin-top: 10px" type="flex" class="row-bg" justify="space-between">
                <el-col :span="6">
                  <el-checkbox v-model="rememberPassword">{{ $t('login.rememberPassword') }}</el-checkbox>
                </el-col>
                <el-col :span="8">
                  <el-button type="success" @click="login" round style="width: 100%">{{ $t('login.loginButton') }}
                  </el-button>
                </el-col>
                <el-col :span="7">
                  <el-link type="primary" @click="dialogVisible = true">{{
                      $t('login.retrievePassword')
                    }}
                  </el-link>
                  <el-link type="primary" style="margin-left: 10px" v-if="openRegister" @click="$router.push({path: '/register'})">{{
                      $t('login.register')
                    }}
                  </el-link>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

    <el-dialog
        title="找回密码"
        :visible.sync="dialogVisible"
        width="80%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="usrEmail" label="邮箱" v-if="this.$store.state.systemConfig.common.sentCodeMode === 0">
          <el-input v-model="ruleForm.usrEmail" placeholder="邮箱" auto-complete="off">
            <el-button slot="append" @click="sendEmailCode">{{ this.timerTime }}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="usrPhone" label="手机号码" v-else>
          <el-input v-model="ruleForm.usrPhone" placeholder="手机号码" auto-complete="off">
            <el-button slot="append" @click="sendEmailCode">{{ this.timerTime }}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="emailCode" label="验证码">
          <el-input v-model="ruleForm.emailCode" placeholder="验证码" prefix-icon="el-icon-lock"
                    auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item prop="usrPwd" label="密码">
          <el-input v-model="ruleForm.usrPwd" placeholder="新密码" prefix-icon="el-icon-lock"
                    auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePwd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import users from "@/request/users";
import CryptoUtil from "@/utils/cryptoUtil";
import store from "../../store";

export default {
  name: "login",
  data() {
    return {
      cryptoU: new CryptoUtil(),
      ask: new users(),
      lang: 'English',
      userInfo: {
        usrName: '',
        usrPwd: ''
      },
      rememberPassword: false, // 记住密码
      wechatOpenId: null,
      dialogVisible: false,
      timerTime: '发送',
      ruleForm: {
        emailCode: '',
        usrPwd: '',
        usrEmail: '',
        usrPhone: ''
      },
      prevUrl: "/",
      rules: {
        usrEmail: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
        emailCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 5, max: 8, message: '长度在 5 到 8 个字符', trigger: 'blur'}
        ],
        usrPwd: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 5, max: 8, message: '长度在 5 到 8 个字符', trigger: 'blur'}
        ],
        usrPhone: [
          {
            required: this.$store.state.systemConfig.common.sentCodeMode === 1,
            message: '请输入手机号码',
            trigger: 'blur'
          },
          {min: 11, max: 11, message: '长度在11个字符', trigger: 'blur'}
        ]
      },
      openRegister: false
    }
  },
  methods: {
    changeLang() {
      this.$i18n.locale = "en"
    },
    /**
     * 发送邮箱验证码
     */
    sendEmailCode() {
      const this_ = this;
      const isEmail = this.$store.state.systemConfig.common.sentCodeMode === 0;
      this.$refs.ruleForm.validateField(isEmail ? 'usrEmail' : 'usrPhone', result => {
        if (!result) {
          if (this.timer == null) {
            this.ask.sendEmailCode({'usrEmail': isEmail ? this_.ruleForm.usrEmail : this_.ruleForm.usrPhone}).then(res => {
              // if (!res.msg) {
              this.$message({
                showClose: true,
                message: '短信验证码已发送!',
                type: 'info'
              });
              // }
            });
            this.timerTime = 60;
            this.timer = setInterval(() => {
              this.timerTime--;
              if (this.timerTime <= 0) {
                clearInterval(this.timer);
                this.timerTime = "发送";
                this.timer = null;
              }
            }, 1000)
          }
        }
      })
    },
    updatePwd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.ask.updPwd(this.ruleForm).then(res => {
            this.dialogVisible = false
            this.$message({
              showClose: true,
              message: '处理完成',
              type: 'success'
            });
            this.$refs.ruleForm.resetFields();
            // this.$storageHandler.setSessionStorage("loginUser", res.msg);
          })
        } else {
          return false;
        }
      });
    },
    login() {
      // const encryptPwd = this.cryptoU.encryptFun(this.userInfo.usrName + this.userInfo.usrPwd);
      const userData = {
        usrName: this.userInfo.usrName,
        usrPwd: this.userInfo.usrPwd,
        wechatOpenId: this.wechatOpenId
      };
      this.ask.login(userData).then(res => {
        if (res.code === 'success') {
          this.$store.commit("user/setIsLogin", true)
          this.$storageHandler.setSessionStorage("loginUser", JSON.stringify(res.msg));
          // this.$store.commit("user/setUserInfo", res.msg)
          // this.resetSetItem("loginUser", JSON.stringify(res.msg));
          // this.$router.push({path: '/'})
          if (this.rememberPassword) {
            this.$storageHandler.setLocalStorage("userInfo", userData);
          } else {
            this.$storageHandler.removeLocalStorage('userInfo');
          }
          const list = ["/wechatSso", "/"]
          if (list.some(item => this.prevUrl == item)) {
            this.$router.push({path: '/'})
          } else {
            this.$router.go(-1)
          }
        }
      })
    }
  },
  created() {
    console.log(this.$route.query.register)
    this.wechatOpenId = this.$route.query.wechatCode
    this.openRegister = this.$route.query.register == 1
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevUrl = from.fullPath
    })
  },
  mounted() {
    const userInfo = this.$storageHandler.getLocalStorage('userInfo');
    if (userInfo != null) {
      this.userInfo = userInfo
      this.rememberPassword = true
    }
  }
}
</script>

<style scoped>

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  height: 92vh;
  padding: 0;
  /*background: linear-gradient(to right, #67c77a, #3fac55)*/
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
}

#inputContent {
  margin: 0 auto;
  background-color: white;
  border-radius: 25px;
  line-height: 50px;
}
</style>
